import React, { useState, useEffect } from "react";
import MainLayout from "../nav/MainLayout";
import Userinfo from "./Userinfo";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Home = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const baseURl = "https://aaysapi.azurewebsites.net/form/userinfo";

  const fetchData = async () => {
    setApiLoading(true);
    try {
      const response = await fetch(baseURl);
      if (response.statusText.toLowerCase() !== "ok") {
        setApiError("Oops! Something went wrong...");
        setApiLoading(false);
        // throw new Error("Something went wrong....");
      } else {
        const responseData = await response.json();
        if (responseData && responseData.length === 0) {
          setApiLoading(false);
          setApiError("No Data Available. . .");
        } else {
          setTimeout(() => {
            setApiLoading(false);
          }, 1000);
          setUserData(responseData);
        }
      }
    } catch (error) {
      setApiLoading(false);
      setApiError("Oops! Something went wrong. . .");
      console.error("Error:", error.message);
      // Handle error
    }
  };

    const sortedAsc = userData.slice().sort((a, b) => b.todayDate.localeCompare(a.todayDate));

  return (
    <div style={{ background: "#1e2130" }} id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />
          <div id="mainHome">
            <Userinfo
              userData={sortedAsc || []}
              apiLoading={apiLoading}
              apiError={apiError}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
