import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { FaDownload } from "react-icons/fa6";

const ReportGenerate = ({ excelData, fileName, transformData = () => {} }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  var wscols = [
    { wch: 20 },
    { wch: 40 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];
  const addStyles = (workSheet) => {
    Object.entries(workSheet)
      .filter(([k, v]) => k.charAt(0) === "A")
      .forEach(([k, v]) => {
        if (v.v === "Year") {
          v.s = {
            fill: {
              fgColor: { rgb: "FFFFFF" },
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            font: {
              bold: false,
            },
          };
        } else {
          v.s = {
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
            width: {
              auto: true,
            },
            height: {
              auto: true,
            },
            font: {
              bold: false,
            },
          };
        }
        if(k=== 'A1'){
          v.s = {
            // fill: {
            //   fgColor: { rgb: "bb1ccc" },
            // },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            font: {
              bold: true,
            },
          };

        }
      });

    Object.entries(workSheet)
      .filter(([k, v]) =>
        ["B", "C", "D", "E", "F", "G", "H", "I","j"].includes(k.charAt(0))
      )
      .forEach(([k, v]) => {
        v.s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          width: {
            auto: true,
          },
          height: {
            auto: true,
          },
        };

        if(k=== 'A1'|| k=== 'B1' || k=== 'C1' || k=== 'D1' || k=== 'E1' || k=== 'F1' || k=== 'G1' || k=== 'H1' || k=== 'I1'){
          v.s = {
            // fill: {
            //   fgColor: { rgb: "bb1ccc" },
            // },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            font: {
              bold: true,
            },
          };

        }
      });
  };

  const excelDataLength = excelData && excelData.length;
  const rowHeight = Array(excelDataLength)
    .fill()
    .map((item) => ({ hpt: 30 }));

  const exportTOExcel = async () => {
    const transformedData = transformData(excelData);
    const ws = XLSX.utils.json_to_sheet(transformedData);
    const range = ws["!ref"];
    ws["!autofilter"] = { ref: range };
    addStyles(ws);
    ws["!cols"] = wscols;
    ws["!rows"] = rowHeight;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const exelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([exelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div style={{alignSelf:"flex-start"}}>
      <button title="Export In Excel" className="export-btn" onClick={(e) => exportTOExcel(fileName)}>
        <FaDownload />
      </button>
    </div>
  );
};

export default ReportGenerate;
