import React, { useEffect, useState } from "react";
// import Navbar from "../components/NavBar/NavBar";
import ReportGenerate from "../ReportGenerate";
import "./Userinfo.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  FaRedo,
  FaSearch,
  FaSortAlphaDown,
  FaSortAlphaUp,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import $ from "jquery";
import ReactPaginate from "react-paginate";

const Userinfo = ({ userData, apiError, apiLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(userData);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setselectedCity] = useState("");
  const [selectedState, setselectedState] = useState("");

  const [sortBy, setSortBy] = useState("username");
  const [sortOrder, setSortOrder] = useState("");
  const [currentPagelimit, setPageLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // Filter user data based on search term and selected country
  useEffect(() => {
    const filteredData = userData.filter((user) => {
      return (
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.countryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.state.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

   

    const sortedData = sortUsers(filteredData);
    setFilteredUsers(sortedData);
  }, [searchTerm, selectedCountry, userData, sortOrder, sortBy]);

  // Sort user data based on selected field and order
  const sortUsers = (data) => {
    return data.sort((a, b) => {
      const fieldA = a[sortBy].toLowerCase();
      const fieldB = b[sortBy].toLowerCase();
      if (sortOrder === "asc") {
        return fieldA.localeCompare(fieldB);
      } else if (sortOrder === "desc") {
        return fieldB.localeCompare(fieldA);
      }
      else{
        return false
      }
    });
  };

  // Toggle sort order and set sort field
  const handleSort = (field) => {
    if (field === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  // Get unique country names from user data
  const uniqueCountries = [
    ...new Set(
      userData &&
        userData
          .map((user) => user.countryName)
          .filter((country) => country !== "")
    ),
  ];

  // Function to reset filters and sorting
  const handleResetFilter = () => {
    setSearchTerm("");
    setSelectedCountry("");
    setSortBy("username");
    setSortOrder("");
    setFilteredUsers(userData);
    setCurrentPage(1);
    setselectedCity("")
    setselectedState("")

  };

  const renderSkeletonRows = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <tr key={`skeleton-${index}`}>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
        <td>
          <Skeleton height={20} />
        </td>
       
       
      </tr>
    ));
  };

  const transformData = (excelData) => {
    const newArray = excelData.map((obj) => {
      const {
        username,
        address,
        city,
        countryName,
        phoneNumber,
        endDate,
        startDate,
        state,
        zip,
        todayDate
      } = obj;
      return {
        Name: username,
        Address: address + ', ' + city + ', ' + state + ', ' + zip,
        City: city,
        Country: countryName,
        Phone: phoneNumber,
        "End Date": endDate,
        "Start Date": startDate,
        "Last Updated": todayDate,
      };
    });
    return newArray;
  };
  // const sortedAsc = filteredUsers.slice().sort((a, b) => b.todayDate.localeCompare(a.todayDate));

  console.log(filteredUsers)

  const data = filteredUsers === null ? " " : Object.values(filteredUsers);
  const search_parameters = ["username","countryName","state"];
  const filter_city = [...new Set(data.map((item) => item.city))];
  const filter_state = [...new Set(data.map((item) => item.state))];

  let filteredData = [];

    filteredData = filteredUsers.filter((obj) => {
        return obj;
      });


function search(filteredData) {
  return filteredData.filter(
    (item) =>
      item.countryName.includes(selectedCountry) &&
     item.city.includes(selectedCity) &&
     item.state.includes(selectedState) && 
 
      search_parameters.some(
        (parameter) =>
          item[parameter].toString().toLowerCase().includes(searchTerm.toLowerCase()) 
      )
     
  );
}
let NUM_OF_RECORDS = search(filteredData).length;
if (NUM_OF_RECORDS <= currentPagelimit) {
  $(".pagination").hide(true);
} else {
  $(".pagination").show(true);
}

const currentData = search(filteredData).slice(
  (currentPage - 1) * currentPagelimit,
  (currentPage - 1) * currentPagelimit + currentPagelimit
);

const pageCount = Math.ceil(search(filteredData).length / currentPagelimit);

const handelepageclick = (data) => {
  console.log(data.selected);
  setCurrentPage(data.selected + 1);
};


  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      {/* <Navbar /> */}
      <div id="user-info-cont">
        <div className="intro-cont">
          <div className="user-info-heading">
            Work Location Declaration Form Results (User Information)
          </div>
          {!apiLoading && (
            <div>
            <ReportGenerate
              excelData={search(filteredData)}
              fileName={`Work-Location-Declaration-Form${new Date().getTime()}`}
              transformData={transformData}
            />
          </div>
          )}
          
        </div>

        <div className="search-container">
          <div className="search-box">
            <FaSearch className="search-icon icon" />
            <input
              className="search-input"
              type="text"
              placeholder={
                searchTerm ? "" : "Search by Name. . ."
              }
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <FaXmark
                className="close-icon icon"
                onClick={() => setSearchTerm("")}
              />
            )}
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <div className="filter-section">
              <select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                className="filter-select"
              >
                <option value="">Filter by Country</option>
                {uniqueCountries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-section">
              <select
                value={selectedState}
                onChange={(e) => setselectedState(e.target.value)}
                className="filter-select"
              >
                <option value="">Filter by State</option>
                {filter_state.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-section">
              <select
                value={selectedCity}
                onChange={(e) => setselectedCity(e.target.value)}
                className="filter-select"
              >
                <option value="">Filter by City</option>
                {filter_city.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              <div  onClick={handleResetFilter} className="resetBox">
                <FaRedo className="refresh-icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="details-section">
          <p className="user-length">
            Total Number of Users:{" "}
            <span>
              {search(filteredData) && search(filteredData).length > 0
                ? search(filteredData).length
                : 0}
            </span>{" "}
          </p>

          <div
            style={{
              display: "flex",
              gridGap: "10px",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handelepageclick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage - 1}
              />
            </div>
          </div>
        </div>

        <div className="table-container">
          <table className="tableData">
            <thead>
              <tr>
                <th
                  style={{ borderRadius: "6px 0px 0px 0px", width: "12%" }}
                  onClick={() => handleSort("username")}
                >
                  <div className="Name-cont">
                    <p>Name </p>
                    {sortBy === "username" && (
                      <span className="sort-cont">
                        {sortOrder === "asc" ? (
                          <FaSortAlphaDown />
                        ) : (
                          <FaSortAlphaUp />
                        )}
                      </span>
                    )}
                  </div>
                </th>
                <th style={{ width: "20%" }}>Address</th>
                <th style={{ width: "9%" }}>Country</th>
                <th style={{ width: "12%" }}>Phone</th>
                <th style={{ width: "9%" }}>Start Date</th>
                <th style={{ width: "9%" }}>End Date</th>
                <th style={{ width: "12%" }}>Last Updated</th>
              </tr>
            </thead>

            <tbody>
              {apiLoading ? (
                renderSkeletonRows()
              ) : (
                <>
                  {currentData && currentData.length > 0 && !apiError ? (
                    currentData.map((userInfo, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ paddingLeft: "16px" }}>
                            {userInfo.username}
                          </td>
                          <td>
                            {userInfo.address}, {userInfo.city},{" "}
                            {userInfo.state}, {userInfo.zip}
                          </td>
                          <td>{userInfo.countryName}</td>
                          <td>{userInfo.phoneNumber}</td>
                          <td>{userInfo.startDate}</td>
                          <td>{userInfo.endDate}</td>
                          <td>{userInfo.todayDate}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9">
                        {apiError &&
                        apiError.toLowerCase().includes("something") ? (
                          <>
                            {apiError}{" "}
                            <span
                              className="refresh-btn"
                              onClick={() => window.location.reload()}
                            >
                              Please try again
                            </span>
                          </>
                        ) : (
                          apiError
                        )}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default Userinfo;
